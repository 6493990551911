.containerHeader {
  // background-color: aqua;
  width: 100%;
  position: absolute;

  div {
    @media (max-width: 1000px) {
      display: flex;
      justify-content: center;
      svg {
        margin-left: inherit !important;
      }
    }

    svg {
      // margin-top: 3.2%;
      margin-top: 60px;
      margin-left: 123px;
    }
  }
}
