.containerHome {
  background-color: #ffffff;
  max-width: 1320px;
  margin: 130px auto auto;

  // CONTENIDO
  // CARDS
  .contentHome {
    // CONTENEDOR DE CARD
    .containerCard {
      background: #fff;
      border-radius: 12px;
      box-shadow: 0px 0px 15px 0px #ecebf9;
      margin-bottom: 100px;
      margin-left: 20px;
      margin-right: 20px;

      // CARD
      .contentCard {
        display: flex;
        @media (max-width: 1055px) {
          flex-direction: column;
          border-radius: 12px;
          overflow: hidden;
        }

        .contentInfo {
          margin: 50px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;

          h1 {
            color: #000;
            font-family: "Roboto";
            font-size: 23px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }

          p {
            color: #000;
            font-family: "Roboto";
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            margin-top: 20px;
            margin-bottom: 20px;
          }

          button {
            border-radius: 50px;
            border: 2px solid #1300fd;

            color: #1300fd;
            font-family: "Roboto";
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;

            :hover {
              background-color: inherit !important;
            }
          }
        }
      }
    }

    // CONTENIDO OCULTO
    .cardInfo {
      margin-bottom: 150px;
      justify-content: flex-start;

      @media (max-width: 900px) {
        justify-content: center;
      }

      .grid {
        @media (max-width: 470px) {
          width: 100%;
          margin-left: 20px;
          margin-right: 20px;

          .subCard {
            width: auto !important;
          }
        }

        .subCard {
          background-color: #ffffff;
          width: 395px;
          min-height: 415px;
          border-radius: 12px;
          box-shadow: 0px 0px 20px 0px #ecebf9;
          margin: 30px auto !important;

          div {
            padding-top: 30px;
            padding-left: 30px;
            padding-right: 30px;
            padding-bottom: 30px;

            .titleArea {
              // CONTENEDOR
              display: inline-flex;
              border-radius: 50px;
              border: 1px solid #000;
              padding: 5px 10px;

              // TEXTO
              color: #000;
              font-family: "Roboto";
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            h1 {
              color: #000;
              font-family: "Roboto";
              font-size: 25px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              margin-top: 20px;
              margin-bottom: 5px;
            }

            p {
              color: #000;
              font-family: "Roboto";
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-top: 15px;
            }

            span {
              color: #000;
              font-family: "Roboto";
              font-size: 25px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }
    }
  }

  // INFORMACION
  .contentBtn {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .btnFormulario {
      background-color: #1300fd;
      border-radius: 50px;
      padding: 15px 30px;

      color: #fff;
      font-family: "Roboto";
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration: none;
      transition: transform ease-out 300ms;
    }

    p {
      margin-top: 100px;
      margin-bottom: 20px;

      color: #000;
      text-align: center;
      font-family: "Roboto";
      font-size: 23px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    span {
      color: #000;
      text-align: center;
      font-family: "Roboto";
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    // RESPONSIVE INFORMACION
    @media (max-width: 1300px) {
      .btnFormulario {
        font-size: 20px;
      }
      p {
        font-size: 18px;
      }
      span {
        font-size: 30px;
      }
    }
  }
}
