// CONTENIDO DE IMAGEN
.containerImage {
  background-image: url("../images/backgroundImage.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 904px;
  display: flex;

  // RESPONSIVE FONDO
  @media (max-height: "1080px") {
    height: 100vh !important;
  }

  // CONTENIDO TITULO
  .contentTitle {
    width: 100%;
    height: 50%;
    background-image: url("../images/Curva.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    margin-top: auto;
    margin-bottom: 0px;

    div {
      max-width: 1300px;
      padding: 0px 10%;

      p {
        color: #fff;
        font-family: "Montserrat";
        font-size: 100px;
        font-style: normal;
        font-weight: 800;
        line-height: 106.188%;
        margin-bottom: 25px;
      }

      span {
        color: #fff;
        font-family: "Montserrat";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 136.188%;
      }

      // RESPONSIVE
      @media (max-width: 1598px) {
        p {
          font-size: 90px !important;
        }
      }
      @media (max-width: 1440px) {
        p {
          font-size: 80px !important;
        }
      }
      @media (max-width: 1280px) {
        p {
          font-size: 70px !important;
        }
      }
      @media (max-width: 1120px) {
        p {
          font-size: 60px !important;
        }
        span {
          font-size: 18px;
        }
      }
      @media (max-width: 960px) {
        p {
          font-size: 50px !important;
        }
        span {
          font-size: 16px;
        }
      }
      @media (max-width: 800px) {
        p {
          font-size: 40px !important;
        }
        span {
          font-size: 14px;
        }
      }
      @media (max-width: 640px) {
        p {
          font-size: 30px !important;
        }
        span {
          font-size: 12px;
        }
      }
    }
  }
}
